<template>
<KTCard>
  <template #title>
    <div class="d-flex align-items-center flex-wrap mr-2">
      <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">{{ $t('ROLES_EMPLOYEES.TITLE_EDIT') }}</h5>
    </div>
  </template>
  <template #toolbar>
    <div class="example-tools justify-content-center">
      <router-link :to="{ name: 'roleBusinessUser' }" class="btn btn-secondary font-weight-bold btn-sm">
        <span class="svg-icon">
          <inline-svg src="/media/svg/icons/Navigation/Angle-double-left.svg" />
        </span>
        {{ $t('BASE.BACK') }}
      </router-link>
    </div>
  </template>
  <template #body>
    <b-overlay :show="busy" variants="transparent" opacity="0.4" rounded="sm">
      <b-container fluid class="p-0">
        <b-row>
          <b-col cols="12">
            <b-form-group :label="`${$t('ROLES_EMPLOYEES.NAME')}`">
              <InputForm
                id="input-1"
                v-model="$v.form.name.$model"
                :state="validateState('name')"
                :placeholder="$t('PLACEHOLDER.NAME')"
                trim
                @input="clearServerError('name')"
                aria-describedby="input-1-feedback"
              />
              <b-form-invalid-feedback id="input-1-feedback">
                <template v-if="serverErrors.name">{{ serverErrors.name[0] }}</template>
                <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.NAME') }) }}</template>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group :label="`${$t('FORM.EMPLOYEES')}`">
              <SelectInfinityScroll
                id="select-2"
                v-model="users"
                :search.sync="searchEmployees"
                api="company/business-users"
                :params="paramsEmployees"
                clearable
                @input="onSelect"
                aria-describedby="select-2-feedback"
              >
                <template #selected="{ selected }">
                  <span class="font-weight-bold">{{ selected.firstName + ' ' + selected.lastName }}</span>
                </template>
                <template #option="{ option }">
                  <span class="font-weight-bold">{{ option.firstName + ' ' + option.lastName }}</span>
                </template>
              </SelectInfinityScroll>
              <b-list-group class="mt-4">
                <b-list-group-item v-for="(item, index) in form.users" :key="item.uuid">
                  <div class="d-flex justify-content-between align-items-center">
                    {{ item.firstName + ' ' + item.lastName }}
                    <span class="svg-icon svg-icon-secondary svg-icon-lg cursor-pointer" @click.prevent="onRemove(index)">
                      <inline-svg src="/media/svg/icons/Navigation/Close.svg" />
                    </span>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group :label="`${$t('FORM.PERMISSIONS')}`">
              <b-tabs content-class="mt-3" justified>
                <b-tab v-for="item in items" :title="item.attribute_trans" :key="item.uuid">
                  <VueTree
                    v-model="permissions[item.attribute]"
                    :tree-data="item.children"
                    textField="attribute_trans"
                  />
                </b-tab>
              </b-tabs>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div class="text-right">
              <b-button :disabled="loading || $v.form.$anyError" @click="onSubmit" variant="primary" size="sm">
                <b-spinner v-if="loading" small variant="light" />
                {{ $t('FORM.SUBMIT') }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-overlay>
  </template>
</KTCard>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import RepoAcl from '@/core/repository/company/aclRepository'
import RepoUserGroups from '@/core/repository/company/userGroupsRepository'
import { TIMEOUT_REDIRECT } from '@/constants/base'
import KTCard from '../../../../content/Card'
import InputForm from '../../../../../components/forms-items/input'
import serverVuelidate from '../../../../../mixins/serverVuelidate'
import SelectInfinityScroll from '../../../../../components/forms-items/SelectInfinityScroll'
import VueTree from '../../../../../components/UI/tree-checkbox/VueTree'

export default {
  name: 'EditBusinessUser',
  components: {
    VueTree,
    SelectInfinityScroll,
    InputForm,
    KTCard,
  },
  mixins: [validationMixin, serverVuelidate],
  data() {
    return {
      busy: true,
      searchEmployees: '',
      items: [],
      loading: false,
      users: null,
      searchRole: '',
      permissions: {},
      form: {
        name: '',
        users: [],
        permissions: [],
      },
      validations: {
        form: {
          name: { required },
          users: {},
          permissions: {},
        },
      },
    }
  },
  computed: {
    paramsEmployees() {
      return {
        search: this.searchEmployees,
        role: 'ROLE_BUSINESS_ADMIN',
      }
    },
  },
  mounted() {
    this.fetchData()
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('ROLES_EMPLOYEES.TITLE_EDIT') }])
  },
  methods: {
    fetchData() {
      this.busy = true
      Promise.all([
        RepoAcl.listTree(),
        RepoUserGroups.get(this.$route.params.id),
      ])
        .then((response) => {
          this.items = response[0].data.payload.items
          this.setItem(response[1].data.payload)
          this.items.forEach((item) => {
            this.$set(this.permissions, item.attribute, response[1].data.payload.permissions.map((m) => m.uuid))
          })
        })
        .finally(() => this.busy = false)
    },
    onSelect(item) {
      const uuids = this.form.users.map((m) => m.uuid)
      if (!uuids.includes(item.uuid)) this.form.users.push(item)
      this.users = null
    },
    onRemove(index) {
      this.form.users.splice(index, 1)
    },
    setItem(item) {
      Object.keys(this.form).forEach((propName) => {
        if (
          this.form.hasOwnProperty(propName)
            && item.hasOwnProperty(propName)
        ) {
          if (item[propName] !== null && item[propName] !== undefined) {
            this.form[propName] = item[propName]
          }
        }
      })
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    async onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      this.clearServerErrors()
      this.loading = true

      const obj = { ...this.form }
      obj.users = obj.users.map((m) => m.uuid)
      obj.permissions = Object.keys(this.permissions).length ? Array.from(new Set([].concat(...Object.values(this.permissions)))) : []
      RepoUserGroups.patch(this.$route.params.id, obj).then(() => {
        this.$bvToast.toast(this.$t('TOAST.EDITED'), {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 2000,
          solid: true,
        })
        setTimeout(() => this.$router.push({ name: 'roleBusinessUser' }), TIMEOUT_REDIRECT)
        this.loading = false
      }).catch((err) => {
        this.loading = false
        this.seServerErrors(err.response.data)
      })
    },
  },
}
</script>

<style scoped>

</style>
